<template>
  <div v-cloak>
    <div class="cms-nav cms-md-hidden cms-sm-hidden cms-ssm-hidden" ref="page"
         :style="{'background':'url('+defaultUrl+' )','opacity': '1'}">
      <div class="cms-nav-top cms-sm-hidden  cms-md-hidden cms-ssm-hidden">
        <div class="cms-nav-left left cms-md-hidden cms-sm-hidden cms-ssm-hidden">
          <home-left></home-left>
        </div>
        <div class="left cms-nav-right">
          <b-carousel id="cms-nav-carousel" v-model="slide"
                      :interval="3000"
                      controls indicators fade
                      style="text-shadow: 1px 1px 2px #333;"
                      @sliding-start="onSlideStart"
                      @sliding-end="onSlideEnd">
            <b-carousel-slide v-for="(items, index) of carouselList" :key="'items-1-'+index">
              <template v-slot:img>
                <div style="height: 100%;">
                  <b-img fluid :src="items.imagesUrl[0].url" style="width: 100%"/>
                </div>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
    <el-carousel class="cms-lg-hidden cms-xl-hidden " id="app-carousel" :height="bannerHeight+'px'">
      <el-carousel-item v-for="(items,index) in carouselList" :key="'items-'+index" interval="3000">
        <el-row>
          <el-col v-for="(item ,i) in items.logoUrl" :key="'item'+i" class="curse">
            <img ref="bannerHeight" class="w-100" :src="item.url" alt="" @load="imgLoad">
          </el-col>
        </el-row>
        <!--        <div ref="bannerHeight" v-for="(item ,i) in items.logoUrl" :key="'item'+i" class="curse" @load="imgLoad"-->
        <!--             :style="{'background':'url('+item.url+') no-repeat center center','background-size':'cover','height':'100%'}">-->
        <!--        </div>-->
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import HomeLeft from "./home-left";
import $ from "jquery";

export default {
  name: "home-carousel",
  props: ["navHeight"],
  components: {HomeLeft},
  data() {
    return {
      carouselList: [],
      slide: 0,
      sliding: null,
      defaultUrl: "",
      bannerHeight: [],
    }
  },
  created() {
    /*初始化轮播图*/
    this.selectPromotionList("carousel", "carousel", "carouselList");
    let that = this;
    this.$nextTick(() => {
      setTimeout(function () {
        that.bannerHeight = that.$refs.bannerHeight[0].height;
        that.imgLoad();
        that.getNavHeight();
      }, 500)
    })
  },
  mounted() {
    this.$nextTick(() => {
      $(".carousel-inner").height("100%");
      this.defaultUrl = this.carouselList;
      this.getNavHeight();
    })
    window.addEventListener('resize', () => {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
      })
      this.imgLoad();
      this.getNavHeight();
    }, true);

  },
  methods: {
    imgLoad() {
      this.$nextTick(() => {
        this.bannerHeight = this.$refs.bannerHeight[0].height;
      })
    },
    onSlideStart(slide) {
      this.defaultUrl = JSON.parse(this.carouselList[slide].bgUrl)[0].url;
    },
    onSlideEnd(slide) {
      console.log(slide);
    },
    getNavHeight: function () {
      let clientWidth = window.innerWidth;
      let clientHeight = window.innerHeight;
      if (clientWidth >= 960) {
        let cmsHead = document.getElementById("cms-head").clientHeight;
        let cmsFooter = document.getElementById("cms-footer").clientHeight;
        this.navHeight = (clientHeight - cmsHead - cmsFooter - 15) + "px";
        this.$refs.page.style.height = this.navHeight;
      }
    },
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then((data) => {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        that.$http.get(that.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId
          }
        }).then((data) => {
          that.zhuanquSize = data.data.result.length;
          for (var i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              if (i == 0) {
                //轮播图初始化
                that.defaultUrl = JSON.parse(data.data.result[i].bgUrl)[0].url;
              }
              console.log(that.defaultUrl);
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
              data.data.result[i].logoUrl = JSON.parse(data.data.result[i].logoUrl);
            }
          }
          that[entityListName] = data.data.result;
        }).catch(function (error) {
          console.log(error)
        })
      }).catch(function (error) {
        console.log(error)
      })
    },
  }
  ,

}
</script>
<style scoped>


.cms-nav-bg {
  width: 100%;
}

.carousel-inner > .carousel-item > img {
  min-width: 100%;
  height: 500px;
}

.carousel-inner {
  height: 1000px !important;
}


.carousel-b-s {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 960px;
  height: 510px;
}

.img-fluid {
  height: 100% !important;
}

.carousel-caption {
  position: relative !important;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cms-nav-left {
    width: 176px;
    height: 304px;
    background: white;
    position: relative;
  }

  .carousel-inner {
    height: 304px !important;
  }

  .cms-nav {
    min-width: 960px;
    width: 100%;
    height: 304px;
    min-height: 350px !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cms-nav-top {
    min-width: 960px;
    width: 100%;
    height: 304px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  #cms-nav-carousel {
    width: 800px !important;
    height: 304px;
  }

  .carousel-item {
    height: 304px !important;
  }
}

@media (min-width: 576px)  and (max-width: 768px) {
  .cms-nav {
    width: 100%;
    max-height: 300px;
    height: 200px;

    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #app-carousel {
    width: 100% !important;
    height: auto !important;
  }
}

@media screen  and (max-width: 576px) {
  .cms-nav {
    width: 100%;
    max-height: 300px;
    height: 200px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #app-carousel {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  #app-carousel {
    width: 720px !important;
    margin: 0 auto;
  }

  .cms-nav-left {
    width: 176px;
    height: 304px;
    background: white;
    position: relative;
  }

  .carousel-inner {
    height: 304px !important;
  }

  .cms-nav {
    min-width: 960px;
    width: 100%;
    height: 304px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cms-nav-top {
    width: 100%;
    height: 304px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }


  #cms-nav-carousel {
    width: 800px !important;
    height: 304px;
  }

  .carousel-item {
    height: 304px !important;
  }
}

@media (min-width: 1200px) {
  .cms-nav-left {
    width: 210px;
    height: 368px;
    background: white;
    position: relative;
  }

  .carousel-inner {
    height: 368px !important;
  }

  .cms-nav {
    min-width: 1160px;
    width: 100%;
    min-height: 400px !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cms-nav-top {
    min-width: 1160px;
    width: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-caption {
    left: 0px !important;
  }

  #cms-nav-carousel {
    width: 950px;
    height: 368px;
  }

  .carousel-item {
    height: 368px !important;
  }
}

</style>
