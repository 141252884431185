<template>
  <div class="home">
    <cms-header class="cms-head" id="cms-head"></cms-header>
    <div class="main">
      <home-carousel></home-carousel>
    </div>
    <div class="footer" id="cms-footer">
      <home-choose></home-choose>
      <cms-foot></cms-foot>
    </div>
  </div>
</template>

<script>
import HomeChoose from "./home-choose";
import CmsFoot from "./cms-foot";
import HomeCarousel from "./home-carousel";
import CmsHeader from "../../components/commoon/component/cms-header";

export default {
  name: "new-home",
  components: {
    CmsHeader,
    HomeCarousel,
    HomeChoose,
    CmsFoot,
  }, created() {
    document.title = this.title;
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

html {
  /*height: 100%;*/
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
}

.header {
}

.main { /* main的padding-bottom值要等于或大于footer的height值 */
  /*padding-bottom: 10px;*/
}

/*.footer {*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*}*/
</style>

