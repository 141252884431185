<template>
  <div class="choose-main">
    <el-row class="choose-main-row">
      <el-col :span="4" class="h-100">
        <div class="choose-main-login">
          <img src="../../assets/image/pc/jurassic.png" alt="">
          <span class="choose-main-item-logo">登录/注册</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="choose-main-item" @click="login">
          <img src="../../assets/image/new-home/icon_cgf.png" class="h-100" alt="">
          <span class="choose-main-item-span">我是招标方</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="choose-main-item" @click="login">
          <img src="../../assets/image/new-home/icon_tbf.png" class="h-100" alt="">
          <span class="choose-main-item-span">我是投标方</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="choose-main-item" @click="login">
          <img src="../../assets/image/new-home/icon_dls.png" class="h-100" alt="">
          <span class="choose-main-item-span">我是代理机构</span>
        </div>
      </el-col>
      <el-col :span="5">
        <div class="choose-main-item" @click="login">
          <img src="../../assets/image/new-home/icon_zj.png" class="h-100" alt="">
          <span class="choose-main-item-span">我是评标专家</span>
        </div>
      </el-col>
    </el-row>
    <!--    <div class="choose-main-row">
          <div class="choose-div left" v-on:click="login()">
            <div>
              <div class="choose-image left"><img src="../../assets/image/new-home/icon_cgf.png"
                                                  style="width:100%;height: 100%" alt=""></div>
              <div class="choose-span left"><span>我是采购方</span></div>
            </div>
          </div>
          <div class="choose-div left" v-on:click="login()">
            <div>
              <div class="choose-image left"><img src="../../assets/image/new-home/icon_tbf.png"
                                                  style="width:100%;height: 100%" alt=""></div>
              <div class="choose-span left"></div>
            </div>
          </div>
          <div class="choose-div left" v-on:click="login()">
            <div>
              <div class="choose-image left"><img src="../../assets/image/new-home/icon_dls.png"
                                                  style="width:100%;height: 100%" alt=""></div>
              <div class="choose-span left"><span>我是代理机构</span></div>
            </div>
          </div>
          <div class="choose-div left" v-on:click="login()">
            <div>
              <div class="choose-image left"><img src="../../assets/image/new-home/icon_zj.png"
                                                  style="width:100%;height: 100%" alt=""></div>
              <div class="choose-span left"><span>我是评标专家</span></div>
            </div>
          </div>
        </div>-->
    <div class="choose-main-mobile">
      <div class="mobile-row">
        <div class="choose-div-mobile left" v-on:click="login()">
          <div class="choose-image left"><img src="../../assets/image/new-home/icon_cgf.png"
                                              style="width:100%;height: 100%" alt=""></div>
          <div class="choose-span left"><span>我是招标方</span></div>
        </div>
        <div class="choose-div-mobile left" v-on:click="login()">
          <div class="choose-image left"><img src="../../assets/image/new-home/icon_tbf.png"
                                              style="width:100%;height: 100%" alt=""></div>
          <div class="choose-span left"><span>我是投标方</span></div>
        </div>
      </div>
      <div class="mobile-row">
        <div class="choose-div-mobile left" v-on:click="login()">
          <div class="choose-image left"><img src="../../assets/image/new-home/icon_dls.png"
                                              style="width:100%;height: 100%" alt=""></div>
          <div class="choose-span left"><span>我是代理机构</span></div>
        </div>
        <div class="choose-div-mobile left" v-on:click="login()">
          <div class="choose-image left"><img src="../../assets/image/new-home/icon_zj.png"
                                              style="width:100%;height: 100%" alt=""></div>
          <div class="choose-span left"><span>我是评标专家</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-choose",
  data() {
    return {
      configForm: {},
      introduce: {},
      introduction: {}
    };
  },
  created() {

  },
  methods: {
    login() {
      // location.href = this.member + "/member/login/function.htm?no_sitemesh"
      location.href = this.memberThree + "/ebidding/#/login";
    }
  }
}
</script>

<style scoped>

.choose-main {
  width: 100%;
  height: 85px;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.choose-main-item-logo {
  font-size: 1.6rem;
  color: #525252;
  font-weight: 600;
  margin-left: 10px;
}

.choose-main-item-span {
  font-size: 1.7rem;
  font-weight: 500;
}

.choose-main-item:hover {
  border-bottom: 3px solid #FF5101;
  cursor: pointer;
  color: #FF5101;
}

.choose-main-item {
  height: 75px !important;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 0.5px solid #E5E5E5;
}

.choose-main-login {
  height: 75px !important;
  display: flex;
  align-items: center;
  justify-content: center
}

.choose-main-row {
  width: 1160px;
  height: 100%;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 7px 0px rgba(190, 190, 190, 0.5);
  display: block;
}

.choose-main-mobile {
  display: none;
}

.choose-div {
  width: 25%;
  height: 100%;
  border-right: 1px solid #EEEEEE;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose-div:hover {
  border-bottom: 3px solid #FF5101;
}

.choose-span:hover {
  color: #FF5101;
}

.choose-image {
  width: 60px;
  height: 53px;
  margin: 3px 0 3px 30px;
}

.choose-span {
  width: 108px;
  height: 25px;
  font-size: 17px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  margin: 20px 0 0 10px;
}

@media screen and (max-width: 576px) {
  .choose-main {
    width: 100%;
    height: 200px;
    padding-top: 0;
  }

  .choose-main-row {
    display: none;
  }

  .choose-main-mobile {
    display: block;
    width: 100%;
    height: 100%;
  }

  .mobile-row {
    height: 50%;
    width: 100%;
  }

  .choose-div-mobile {
    width: 46%;
    height: 95%;
    background: #FFFFFF;
    border-radius: 6px;
    border: 0;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 6px;
  }

  .choose-image {
    width: 60px;
    height: 53px;
    margin: 20px 0 3px 0px;
  }

  .choose-span {
    width: 90px;
    height: 16px;
    font-size: 13px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    margin: 40px 0 0 10px;
  }
}

@media (min-width: 576px ) and (max-width: 768px) {
  .choose-main-item {
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-left: 0.5px solid #E5E5E5;
  }

  .choose-main-login {
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .choose-main-item-span {
    font-size: 1rem;
  }


  .choose-main-item-logo {
    font-size: 1.2rem;
    margin-left: 5px;
  }

  .choose-main {
    width: 100%;
    height: 58px;
    padding-top: 10px;
  }

  .choose-main-row {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px 0px rgba(190, 190, 190, 0.5);
    display: block;
  }

  .choose-main-mobile {
    display: none;
  }

  .choose-div {
    width: 25%;
    height: 100%;
    border-right: 1px solid #EEEEEE;
    cursor: pointer;
  }

  .choose-image {
    width: 32px;
    height: 30px;
    margin: 10px 0 3px 30px;
  }

  .choose-span {
    width: 70px;
    height: 16px;
    font-size: 11px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    margin: 15px 0 0 10px;
  }

  .choose-div:hover {
    border-bottom: 3px solid #FF5101;
  }

  .choose-span:hover {
    color: #FF5101;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .choose-main-item {
    height: 50px !important;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 0.5px solid #E5E5E5;
  }

  .choose-main-login {
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .choose-main-item-logo {
    font-size: 1.4rem;
    margin-left: 5px;
  }

  .choose-main-item-logo {
    font-size: 1.3rem;
  }

  .choose-main-item-span {
    font-size: 1.3rem;
  }

  .choose-main {
    width: 100%;
    height: 60px;
  }

  .choose-main-row {
    width: 720px;
    height: 100%;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px 0px rgba(190, 190, 190, 0.5);
    display: block;
  }

  .choose-main-mobile {
    display: none;
  }

  .choose-div {
    width: 25%;
    height: 100%;
    border-right: 1px solid #EEEEEE;
    cursor: pointer;
  }

  .choose-image {
    width: 32px;
    height: 30px;
    margin: 10px 0 3px 30px;
  }

  .choose-span {
    width: 70px;
    height: 16px;
    font-size: 11px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 16px;
    margin: 20px 0 0 10px;
  }

  .choose-div:hover {
    border-bottom: 3px solid #FF5101;
  }

  .choose-span:hover {
    color: #FF5101;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .choose-main-item {
    height: 60px !important;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 0.5px solid #E5E5E5;
  }

  .choose-main-item-logo {
    font-size: 1.5rem;
    margin-left: 5px;
  }

  .choose-main-login {
    height: 60px !important;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .choose-main-item-span {
    font-size: 1.6rem;
  }

  .choose-main {
    width: 100%;
    height: 70px;
  }

  .choose-main-row {
    width: 960px;
    height: 100%;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px 0px rgba(190, 190, 190, 0.5);
    display: block;
  }

  .choose-main-mobile {
    display: none;
  }

  .choose-div {
    width: 25%;
    height: 100%;
    border-right: 1px solid #EEEEEE;
    cursor: pointer;
  }

  .choose-image {
    width: 60px;
    height: 53px;
    margin: 3px 0 3px 30px;
  }

  .choose-span {
    width: 88px;
    height: 25px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 25px;
    margin: 20px 0 0 10px;
  }

  .choose-div:hover {
    border-bottom: 3px solid #FF5101;
  }

  .choose-span:hover {
    color: #FF5101;
  }
}
</style>
