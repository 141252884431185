<template>
  <div>
    <div class="home-left">
      <div class="cms-company-content">
        <div class="cms-company-item">
          <div class="cms-company-item-title">
            {{ manufacture.categoryTitle }}
          </div>
          <div class="row cms-company-item-name">
            <div class="col-6 cms-company-item-span" v-for="(item ,index) in manufactureList" :key="'project'+index">
              <!--   isRecommend，代表在系统存在企业专区  ,newVersionZone：代表是否新版的企业专区         -->
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone =='1'"
                           :title="item.linkText"
                           :to="{'name':'specializedNewZone','query':{'id':item.companyId,'companyId':item.id}}">
                {{ item.linkText }}
              </router-link>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone =='2'"
                           :title="item.linkText"
                           :to="{'name':'specializedNewZoneTwo','query':{'id':item.companyId,'companyId':item.id}}">
                {{ item.linkText }}
              </router-link>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone !='1' && item.newVersionZone !='2'"
                           :title="item.linkText"
                           :to="{'name':'zone','query':{'id':item.companyId,'companyId':item.id}}">
                {{ item.linkText }}
              </router-link>

              <a :href="item.linkUrl" class="cms-company-item-span-a" target="_blank"
                 v-if="index <=3 && item.isRecommend != '1' && (item.linkUrl != undefined && item.linkUrl != null && item.linkUrl !='')">
                <!--  不推荐，有公司地址，链接到其他公司  -->
                {{ item.linkText }}
              </a>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend != '1' && (item.linkUrl == undefined || item.linkUrl == null || item.linkUrl =='')"
                           :title="item.linkText"
                           :to="{'name':'zoneHome'}">
                {{ item.linkText }}
              </router-link>
            </div>
          </div>
          <router-link to="/zone-home">
            <div class="cms-company-show cms-company-show1">
              <div class="cms-company-show-title">
                {{ manufacture.categoryTitle }}
                <!--                —{{ manufactureRecommend.linkText }}-->
              </div>
              <div class="cms-company-describe">
                聚焦制造强省建设，“湖南制造”向“湖南智造”阔步迈进。大力培育先进制造业链主企业，以链聚产，攻关核心技术、加速数字化智能化融合发展，保持稳中向好、稳中提质发展态势，坚定向着打造国家重要先进制造业高地目标前进。“荷云采”招采平台助力制造企业“降低采购成本、提高采购效率、保障采购供应”！
              </div>
              <div class="cms-company-zone">
                <router-link to="/zone-home">进入专区</router-link>
              </div>
            </div>
          </router-link>
        </div>
        <div class="cms-company-item">
          <div class="cms-company-item-title">
            {{ project.categoryTitle }}
          </div>
          <div class="row cms-company-item-name">
            <div class="col-6 cms-company-item-span" v-for="(item ,index) in projectList" :key="'project'+index">
              <!--   isRecommend，代表在系统存在企业专区  ,newVersionZone：代表是否新版的企业专区         -->
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone !='1'  && item.newVersionZone !='2'"
                           :title="item.linkText"
                           :to="{'name':'zone','query':{'id':item.companyId,'companyId':item.id}}">
                {{ item.linkText }}
              </router-link>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone =='1'"
                           :title="item.linkText"
                           :to="{'name':'specializedNewZone','query':{'id':item.companyId,'companyId':item.id}}">
                {{ item.linkText }}
              </router-link>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone =='2'"
                           :title="item.linkText"
                           :to="{'name':'specializedNewZoneTwo','query':{'id':item.companyId,'companyId':item.id}}">
                {{ item.linkText }}
              </router-link>
              <a :href="item.linkUrl" class="cms-company-item-span-a" target="_blank"
                 v-if="index <=3 && item.isRecommend != '1' && (item.linkUrl != undefined && item.linkUrl != null && item.linkUrl !='')">
                <!--  不推荐，有公司地址，链接到其他公司  -->
                {{ item.linkText }}
              </a>
              <router-link class="cms-company-item-span-a" v-if="index<=3 && item.isRecommend != '1' && (item.linkUrl == undefined || item.linkUrl == null || item.linkUrl =='')"
                           :title="item.linkText"
                           :to="{'name':'zoneHome'}">
                {{ item.linkText }}
              </router-link>
            </div>
          </div>
          <router-link to="/zone-home">
            <div class="cms-company-show cms-company-show2">
              <div class="cms-company-show-title">
                {{ project.categoryTitle }}
              </div>
              <div class="cms-company-describe">
                健全招采制度，规范工程招采。结合大数据、人工智能、云计算等先进技术，以数据驱动决策，加快企业数字化转型。建设工程企业流程化、规范化、数字化为一体的招采体系，保障工程招采的阳光规范与高效协同。“荷云采”招采平台助力工程企业“规范招采流程、防止串标围标、实现阳光招采”！
              </div>
              <div class="cms-company-zone">
                <router-link to="/zone-home">进入专区</router-link>
              </div>
            </div>
          </router-link>
        </div>
        <div class="cms-company-item">
          <div class="cms-company-item-title">
            {{ service.categoryTitle }}
          </div>
          <div class="row cms-company-item-name">
            <div class="col-6 cms-company-item-span" v-for="(item ,index) in serviceList" :key="'project'+index">
              <!--   isRecommend，代表在系统存在企业专区  ,newVersionZone：代表是否新版的企业专区         -->
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone !='1' && item.newVersionZone !='2'"
                           :title="item.linkText"
                           :to="{'name':'zone','query':{'id':item.companyId,'companyId':item.id}}">
                {{ item.linkText }}
              </router-link>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone =='1'"
                           :title="item.linkText"
                           :to="{'name':'specializedNewZone','query':{'id':item.companyId,'companyId':item.id}}">
                {{ item.linkText }}
              </router-link>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone =='2'"
                           :title="item.linkText"
                           :to="{'name':'specializedNewZoneTwo','query':{'id':item.companyId,'companyId':item.id}}">
                {{ item.linkText }}
              </router-link>
              <a :href="item.linkUrl" class="cms-company-item-span-a" target="_blank"
                 v-if="index <=3 && item.isRecommend != '1' && (item.linkUrl != undefined && item.linkUrl != null && item.linkUrl !='')">
                <!--  不推荐，有公司地址，链接到其他公司  -->
                {{ item.linkText }}
              </a>
              <router-link class="cms-company-item-span-a" v-if="index<=3 && item.isRecommend != '1' && (item.linkUrl == undefined || item.linkUrl == null || item.linkUrl =='')"
                           :title="item.linkText"
                           :to="{'name':'zoneHome'}">
                {{ item.linkText }}
              </router-link>

            </div>
          </div>
          <router-link to="/zone-home">
            <div class="cms-company-show cms-company-show3">
              <div class="cms-company-show-title">
                {{ service.categoryTitle }}
              </div>
              <div class="cms-company-describe">
                基于数字化招采，整合供应上下游资源，实现生态圈打造，提供一站式智慧招采整体解决方案。供应链金融、全流程招标代理等功能模块，为企业招采提供委托招标、电子保函、履约保函、保证金管理、中标贷等提供增值服务，帮助企业提供专业招采支持和资金支持。“荷云采”招采平台助力企业“整合发展资源、构建服务闭环、高效协同创新”！
              </div>
              <div class="cms-company-zone">
                <router-link to="/zone-home">进入专区</router-link>
              </div>
            </div>
          </router-link>
        </div>
        <div class="cms-company-item">
          <div class="cms-company-item-title">
            {{ cooperate.categoryTitle }}
          </div>
          <div class="row cms-company-item-name">
            <div class="col-6 cms-company-item-span" v-for="(item ,index) in cooperateList" :key="'project'+index">
              <!--     取4家公司  专精特新专门做的专区       -->
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone == '1'"
                           :title="item.linkText"
                           :to="{'name':'specializedNewZone','query':{'id':item.companyId,'companyId':item.id}}">
                <!--推荐，并且使用新版专区-->
                {{ item.linkText }}
              </router-link>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone == '2'"
                           :title="item.linkText"
                           :to="{'name':'specializedNewZoneTwo','query':{'id':item.companyId,'companyId':item.id}}">
                <!--推荐，并且使用新版专区-->
                {{ item.linkText }}
              </router-link>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend == '1' && item.newVersionZone != '1'  && item.newVersionZone != '2' "
                           :title="item.linkText"
                           :to="{'name':'zone','query':{'id':item.companyId,'companyId':item.id}}">
                <!--推荐，并且不使用新版专区-->
                {{ item.linkText }}
              </router-link>
              <a :href="item.linkUrl" class="cms-company-item-span-a" target="_blank"
                 v-if="index<=3 && item.isRecommend != '1' && item.newVersionZone != '1' && item.newVersionZone != '2' && (item.linkUrl != undefined && item.linkUrl != null && item.linkUrl !='')">
                <!--  不推荐，有公司地址，链接到其他公司  -->
                {{ item.linkText }}
              </a>
              <router-link class="cms-company-item-span-a"
                           v-if="index<=3 && item.isRecommend != '1' && item.newVersionZone != '1' && item.newVersionZone != '2' && (item.linkUrl == undefined || item.linkUrl == null || item.linkUrl =='')"
                           :title="item.linkText"
                           :to="{'name':'zoneHome'}">
                <!--不推荐，跳转专区首页-->
                {{ item.linkText }}
              </router-link>
            </div>
          </div>
          <router-link to="/zone-home">
            <div class="cms-company-show cms-company-show4">
              <div class="cms-company-show-title">
                {{ cooperate.categoryTitle }}
              </div>
              <div class="cms-company-describe">
                构建数字化招采专区、跨平台直采商城，支持中小企业融入行业龙头企业供应链创新链，推动行业龙头企业对“小巨人”企业开放资源要素。“荷云采”招采平台致力于打造湖南省“链主企业”+“上市公司”+专精特新“小巨人”企业为一体的大小企业融通发展平台，助力企业高质量发展。
              </div>
              <div class="cms-company-zone">
                <router-link to="/zone-home">进入专区</router-link>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-left", data() {
    return {
      projectList: [],
      serviceList: [],
      cooperateList: [],
      manufactureList: [],
      manufacture: {},
      project: {},
      cooperate: {},
      service: {},
      zone: {},
      serviceRecommend: [],
      projectRecommend: [],
      cooperateRecommend: [],
      manufactureRecommend: [],
    }
  },
  created() {
    /*初始化名企专区*/
    this.selectPromotionList("service_zone", "service", "serviceList", "serviceRecommend");
    this.selectPromotionList("zone", "zone", "zoneList", "");
    this.selectPromotionList("project_zone", "project", "projectList", "projectRecommend");
    this.selectPromotionList("cooperate_zone", "cooperate", "cooperateList", "cooperateRecommend");
    this.selectPromotionList("manufacture_zone", "manufacture", "manufactureList", "manufactureRecommend");
  }
  , methods: {
    selectPromotionList: function (modelId, entityName, entityListName, recommendName) {
      var that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then((data) => {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        that.$http.get(that.base + "/promotion/list.do", {
          params: {
            categoryId: categoryId
          }
        }).then((data) => {
          that.zhuanquSize = data.data.result.length;
          for (var i = 0; i < data.data.result.length; i++) {
            if (data.data.result[i].isRecommend == 1) {
              that[recommendName] = data.data.result[i];
            }
            if (data.data.result[i].imagesUrl != null && data.data.result[i].imagesUrl != "") {
              data.data.result[i].imagesUrl = JSON.parse(data.data.result[i].imagesUrl);
            }
          }
          that[entityListName] = data.data.result;
        }).catch(function (error) {
          console.log(error)
        })
      }).catch(function (error) {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>
.cms-company-item-name {
  padding: 0px;
  margin: 0px;
}

.cms-company-describe {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
}

.home-left {
  width: 210px;
  height: 368px;
  background: #FFFFFF;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

.cms-company-show1 {
  background: url("../../assets/image/pc/company1.png") !important;

}

.cms-company-show2 {
  background: url("../../assets/image/pc/company2.png") !important;

}

.cms-company-show3 {
  background: url("../../assets/image/pc/company3.png") !important;

}

.cms-company-show4 {
  background: url("../../assets/image/pc/company4.png") !important;
}

.cms-company-item-title {
  height: 19px;
  font-size: 1.5rem;
  font-family: MicrosoftYaHei;
  color: #333333;
  line-height: 19px;
  margin-bottom: 6px;
}

.cms-company-describe {
  width: 345px;
  font-size: 1.3rem;
  font-family: MicrosoftYaHei;
  color: #777777;
  line-height: 23px;
  margin-bottom: 5px;
}

.cms-company-zone {
  font-size: 1.4rem;
  font-weight: 500;
  color: #F37400;
  line-height: 19px;
}

.cms-company-show-title {
  height: 34px;
  font-size: 2.3rem;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #000000;
  line-height: 34px;
  margin-bottom: 10px;
}

.cms-company-item-span {
  font-size: 1.3rem;
  font-family: MicrosoftYaHei;
  color: #999999;
  line-height: 16px;
  padding: 0px !important;
  margin: 0px !important;
  margin-bottom: 5px !important;

}

.cms-company-item-span a {
  color: #999999;
  overflow: hidden;
  max-height: 15px;
}


.cms-company-title {
  width: 210px;
  height: 39px;
  background: #FFFFFF;
  box-shadow: 0px 1px 0px 0px rgba(229, 229, 229, 1);
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  align-items: center;
}

.cms-company-content {
  width: 210px;
  height: 368px;
  display: flex;
  flex-flow: column;
}

.cms-company-item {
  flex: 1;
  width: 100%;
  font-size: 1.2rem;
  align-items: center;
  padding: 10px 30px 0px 30px;
}

.cms-company-show {
  width: 950px;
  height: 368px;
  position: absolute;
  top: 0px;
  left: 210px;
  z-index: 100;
  display: none;
  transition: all 1s;
  background-repeat: no-repeat;
  background-size: cover !important;
  padding-left: 70px;
  padding-top: 40px;
}

.cms-company-item:hover .cms-company-show {
  z-index: 5;
  display: block;
}

.cms-company-item:hover {
  cursor: pointer;
  background: #F37400;
  color: #FFFFFF !important;
}

.cms-company-item:hover .cms-company-item-title {
  color: #FFFFFF;
}

.cms-company-item:hover .cms-company-item-span a {
  color: #FFFFFF;
}


/*.cms-company-item-span:hover a {*/
/*  color: #FFFFFF !important;*/
/*}*/

.cms-company-item:hover .cms-company-name {
  cursor: pointer;
  color: white;
}


@media (min-width: 992px) and (max-width: 1200px) {

  .home-left {
    width: 176px;
    height: 304px;
    background: #FFFFFF;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .cms-company-item-title {
    height: 19px;
    font-size: 1.5rem;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 19px;
    margin-bottom: 5px;
  }

  .cms-company-item-span {
    font-size: 1.3rem;
    font-family: MicrosoftYaHei;
    color: #999999;
    line-height: 16px;
    padding: 0px !important;
    margin: 0px !important;
    margin-bottom: 5px;

  }

  .cms-company-title {
    width: 176px;
    height: 39px;
    background: #FFFFFF;
    box-shadow: 0px 1px 0px 0px rgba(229, 229, 229, 1);
    display: flex;
    font-size: 1.4rem;
    justify-content: center;
    align-items: center;
  }

  .cms-company-content {
    width: 176px;
    height: 304px;
    display: flex;
    flex-flow: column;
  }

  .cms-company-item {
    flex: 1;
    width: 100%;
    font-size: 1.2rem;
    align-items: center;
    padding: 10px 0px 0px 15px;
  }

  .cms-company-show {
    width: 800px;
    height: 304px;
    position: absolute;
    background: white;
    top: 0px;
    left: 176px;
    z-index: 100;
    display: none;
    transition: all 1s;
  }

  .cms-company-item:hover .cms-company-show {
    z-index: 5;
    display: block;
  }

  .cms-company-describe {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
}

.cms-company-item-span-a {
  display: inline-block;
  padding-bottom: 1px;
  background-image: linear-gradient(#FFF, #FFF);
  background-position: 0 100%;
  background-size: 0% 1px;
  background-repeat: no-repeat;
  transition: background-size 0.3s,
  background-position 0s 0.3s;
}

.cms-company-item-span-a:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 1px;
  cursor: pointer;
}

.cms-company-item-span-a:focus-visible {
  display: inline-block;
  padding-bottom: 1px;
  background-color: #FFF;
  background-position: 0 100%;
  background-size: 0% 1px;
  background-repeat: no-repeat;
}

.cms-company-zone a {
  color: #F37400;
  display: inline-block;
  padding-bottom: 1px;
  background-image: linear-gradient(#F37400, #F37400);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 1px;
  background-repeat: no-repeat;
  transition: background-size 0.3s,
  background-position 0s 0.3s; /*change after the size immediately*/
}

.cms-company-zone a:hover {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 1px;
  cursor: pointer;
}

.cms-company-zone a:focus-visible {
  display: inline-block;
  padding-bottom: 1px;
  background-color: #F37400;
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 1px;
  background-repeat: no-repeat;
}

</style>
